<template>
  <div>
    <b-form-group :state="state">
      <template v-if="label !== null" v-slot:label>
        {{ label }}
        <span v-if="required" class="text-danger"> (*) </span>
      </template>
      <v-select
        id="school"
        v-model="school"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="schools"
        :disabled="!enable"
        :placeholder="t('Cơ sở')"
        @input="schoolChanged"
        @open="onOpen"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select
      >
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Cơ sở",
    },
    enableAdd: {
      type: Boolean,
      default: true,
    },
    enable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      school: this.defaultValue,
      schools: [],
    };
  },
  created() {
    this.fetchSchools();
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    schoolChanged() {
      this.$emit("input", this.school);
    },
    fetchSchools() {
      useJwt.getSchools().then((res) => {
        this.schools = res.data.items;
      });
    },
    onOpen() {
      if (this.schools.length === 0) {
        this.fetchSchools();
      }
    },
    onItemCreated(newItem) {
      this.school = newItem;
      this.schoolChanged();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
