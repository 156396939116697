<template>
  <b-modal
    id="modal-class-detail"
    ref="refModalClassDetail"
    :title="t('Thông tin lớp học')"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >
    <div class="d-flex justify-content-center mb-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder"> {{ t("Tên lớp") }}: </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.school">
          <b-row>
            <b-col class="font-weight-bolder"> {{ t("Cơ sở") }}: </b-col>
            <b-col class="">
              {{ itemLocal.school.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.numberStudents">
          <b-row>
            <b-col class="font-weight-bolder"> {{ t("Số học sinh") }}: </b-col>
            <b-col class="">
              {{ Number(itemLocal.numberStudents) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.teacherName">
          <b-row>
            <b-col class="font-weight-bolder"> Giáo viên chủ nhiệm: </b-col>
            <b-col class="">
              {{ itemLocal.teacherName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.teacherPhone">
          <b-row>
            <b-col class="font-weight-bolder"> SDT: </b-col>
            <b-col class="">
              {{ itemLocal.teacherPhone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.teacherEmail">
          <b-row>
            <b-col class="font-weight-bolder"> Email: </b-col>
            <b-col class="">
              {{ itemLocal.teacherEmail }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tingee">
          <b-row>
            <b-col class="font-weight-bolder"> Danh sách tài khoản: </b-col>
            <b-col class="">
              {{ itemLocal.tingee.accountNumber }} -
              {{ itemLocal.tingee.vaAccountNumber }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
    <!-- Body -->
  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useClassDetailModal from "./useClassDetailModal";

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
  },
  props: {
    classId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useClassDetailModal(props, emit, refFormObserver);
    const { t } = useI18nUtils();
    const provider = process.env.VUE_APP_PROVIDER;
    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      t,
      provider,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
